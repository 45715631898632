export const symbolPatterns = [
  {
    sign: " ",
    pattern: 11011001100,
    value: 0
  },
  {
    sign: "!",
    pattern: 11001101100,
    value: 1
  },
  {
    sign: "\"",
    pattern: 11001100110,
    value: 2
  },
  {
    sign: "#",
    pattern: 10010011000,
    value: 3
  },
  {
    sign: "$",
    pattern: 10010001100,
    value: 4
  },
  {
    sign: "%",
    pattern: 10001001100,
    value: 5
  },
  {
    sign: "&",
    pattern: 10011001000,
    value: 6
  },
  {
    sign: "'",
    pattern: 10011000100,
    value: 7
  },
  {
    sign: "(",
    pattern: 10001100100,
    value: 8
  },
  {
    sign: ")",
    pattern: 11001001000,
    value: 9
  },
  {
    sign: "*",
    pattern: 11001000100,
    value: 10
  },
  {
    sign: "+",
    pattern: 11000100100,
    value: 11
  },
  {
    sign: ",",
    pattern: 10110011100,
    value: 12
  },
  {
    sign: "-",
    pattern: 10011011100,
    value: 13
  },
  {
    sign: ".",
    pattern: 10011001110,
    value: 14
  },
  {
    sign: "/",
    pattern: 10111001100,
    value: 15
  },
  {
    sign: "0",
    pattern: 10011101100,
    value: 16
  },
  {
    sign: "1",
    pattern: 10011100110,
    value: 17
  },
  {
    sign: "2",
    pattern: 11001110010,
    value: 18
  },
  {
    sign: "3",
    pattern: 11001011100,
    value: 19
  },
  {
    sign: "4",
    pattern: 11001001110,
    value: 20
  },
  {
    sign: "5",
    pattern: 11011100100,
    value: 21
  },
  {
    sign: "6",
    pattern: 11001110100,
    value: 22
  },
  {
    sign: "7",
    pattern: 11101101110,
    value: 23
  },
  {
    sign: "8",
    pattern: 11101001100,
    value: 24
  },
  {
    sign: "9",
    pattern: 11100101100,
    value: 25
  },
  {
    sign: ":",
    pattern: 11100100110,
    value: 26
  },
  {
    sign: ";",
    pattern: 11101100100,
    value: 27
  },
  {
    sign: "<",
    pattern: 11100110100,
    value: 28
  },
  {
    sign: "=",
    pattern: 11100110010,
    value: 29
  },
  {
    sign: ">",
    pattern: 11011011000,
    value: 30
  },
  {
    sign: "?",
    pattern: 11011000110,
    value: 31
  },
  {
    sign: "@",
    pattern: 11000110110,
    value: 32
  },
  {
    sign: "A",
    pattern: 10100011000,
    value: 33
  },
  {
    sign: "B",
    pattern: 10001011000,
    value: 34
  },
  {
    sign: "C",
    pattern: 10001000110,
    value: 35
  },
  {
    sign: "D",
    pattern: 10110001000,
    value: 36
  },
  {
    sign: "E",
    pattern: 10001101000,
    value: 37
  },
  {
    sign: "F",
    pattern: 10001100010,
    value: 38
  },
  {
    sign: "G",
    pattern: 11010001000,
    value: 39
  },
  {
    sign: "H",
    pattern: 11000101000,
    value: 40
  },
  {
    sign: "I",
    pattern: 11000100010,
    value: 41
  },
  {
    sign: "J",
    pattern: 10110111000,
    value: 42
  },
  {
    sign: "K",
    pattern: 10110001110,
    value: 43
  },
  {
    sign: "L",
    pattern: 10001101110,
    value: 44
  },
  {
    sign: "M",
    pattern: 10111011000,
    value: 45
  },
  {
    sign: "N",
    pattern: 10111000110,
    value: 46
  },
  {
    sign: "O",
    pattern: 10001110110,
    value: 47
  },
  {
    sign: "P",
    pattern: 11101110110,
    value: 48
  },
  {
    sign: "Q",
    pattern: 11010001110,
    value: 49
  },
  {
    sign: "R",
    pattern: 11000101110,
    value: 50
  },
  {
    sign: "S",
    pattern: 11011101000,
    value: 51
  },
  {
    sign: "T",
    pattern: 11011100010,
    value: 52
  },
  {
    sign: "U",
    pattern: 11011101110,
    value: 53
  },
  {
    sign: "V",
    pattern: 11101011000,
    value: 54
  },
  {
    sign: "W",
    pattern: 11101000110,
    value: 55
  },
  {
    sign: "X",
    pattern: 11100010110,
    value: 56
  },
  {
    sign: "Y",
    pattern: 11101101000,
    value: 57
  },
  {
    sign: "Z",
    pattern: 11101100010,
    value: 58
  },
  {
    sign: "[",
    pattern: 11100011010,
    value: 59
  },
  {
    sign: "\\",
    pattern: 11101111010,
    value: 60
  },
  {
    sign: "]",
    pattern: 11001000010,
    value: 61
  },
  {
    sign: "^",
    pattern: 11110001010,
    value: 62
  },
  {
    sign: "_",
    pattern: 10100110000,
    value: 63
  },
  {
    sign: "`",
    pattern: 10100001100,
    value: 64
  },
  {
    sign: "a",
    pattern: 10010110000,
    value: 65
  },
  {
    sign: "b",
    pattern: 10010000110,
    value: 66
  },
  {
    sign: "c",
    pattern: 10000101100,
    value: 67
  },
  {
    sign: "d",
    pattern: 10000100110,
    value: 68
  },
  {
    sign: "e",
    pattern: 10110010000,
    value: 69
  },
  {
    sign: "f",
    pattern: 10110000100,
    value: 70
  },
  {
    sign: "g",
    pattern: 10011010000,
    value: 71
  },
  {
    sign: "h",
    pattern: 10011000010,
    value: 72
  },
  {
    sign: "i",
    pattern: 10000110100,
    value: 73
  },
  {
    sign: "j",
    pattern: 10000110010,
    value: 74
  },
  {
    sign: "k",
    pattern: 11000010010,
    value: 75
  },
  {
    sign: "l",
    pattern: 11001010000,
    value: 76
  },
  {
    sign: "m",
    pattern: 11110111010,
    value: 77
  },
  {
    sign: "n",
    pattern: 11000010100,
    value: 78
  },
  {
    sign: "o",
    pattern: 10001111010,
    value: 79
  },
  {
    sign: "p",
    pattern: 10100111100,
    value: 80
  },
  {
    sign: "q",
    pattern: 10010111100,
    value: 81
  },
  {
    sign: "r",
    pattern: 10010011110,
    value: 82
  },
  {
    sign: "s",
    pattern: 10111100100,
    value: 83
  },
  {
    sign: "t",
    pattern: 10011110100,
    value: 84
  },
  {
    sign: "u",
    pattern: 10011110010,
    value: 85
  },
  {
    sign: "v",
    pattern: 11110100100,
    value: 86
  },
  {
    sign: "w",
    pattern: 11110010100,
    value: 87
  },
  {
    sign: "x",
    pattern: 11110010010,
    value: 88
  },
  {
    sign: "y",
    pattern: 11011011110,
    value: 89
  },
  {
    sign: "z",
    pattern: 11011110110,
    value: 90
  },
  {
    sign: "{",
    pattern: 11110110110,
    value: 91
  },
  {
    sign: "|",
    pattern: 10101111000,
    value: 92
  },
  {
    sign: "}",
    pattern: 10100011110,
    value: 93
  },
  {
    sign: "~",
    pattern: 10001011110,
    value: 94
  },
  {
    sign: "ð", // Ã / È / ð
    pattern: 10111101000,
    value: 95
  },
  {
    sign: "ñ", // Ä / É / ñ
    pattern: 10111100010,
    value: 96
  },
  {
    sign: "ò", // Å / Ê / ò
    pattern: 11110101000,
    value: 97
  },
  {
    sign: "ó", // Æ / Ë / ó
    pattern: 11110100010,
    value: 98
  },
  {
    sign: "ô", // Ç / Ì / ô
    pattern: 10111011110,
    value: 99
  },
  {
    sign: "õ", // È / Í / õ
    pattern: 10111101110,
    value: 100
  },
  {
    sign: "ö", // É / Î / ö
    pattern: 11101011110,
    value: 101
  },
  {
    sign: "÷", // Ê / Ï / ÷
    pattern: 11110101110,
    value: 102
  },
  {
    sign: "startA",
    pattern: 11010000100,
    value: 103
  },
  {
    sign: "startB",
    pattern: 11010010000,
    value: 104
  },
  {
    sign: "startC",
    pattern: 11010011100,
    value: 105
  },
  // {
  //   sign: "stop",
  //   pattern: 11000111010,
  //   value: 106
  // },
  { sign: "stop", pattern: 1100011101011, value: 0 }
];
