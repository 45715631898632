import "./reset.css";
import "./styles.css";

import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import QRCode from "react-qr-code";

import { CodeSymbol } from "./components/CodeSymbol";
import { symbolPatterns } from "./components/symbolPatterns";

import honeywellHID from "./assets/honeywell_usb_hid_barcode_scanner.png";
import zebraSSI from "./assets/zebra_ssi_over_usb_cdc.png";
import zebraHID from "./assets/zebra_hid_keyboard_emulation.png";

import logo from "./assets/crocodile.png";
import logoAlt from "./assets/crocodile_alt.png";

const Main = styled("main")`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  perspective: 1000px;
  background-color: #f7f7f7;
  overflow-x: hidden;
`;

const HomeView = styled("div")`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  padding: 0 20px;
`;

const Header = styled("header")`
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 0 0;
  transition: margin 0.3s ease-out;

  &.low {
    margin: 25px 0;
    transition: margin 0.3s ease-out;

    h1 {
      margin-left: -25px;
      transition: margin-left 0.3s ease-out;
    }
  }

  h1 {
    font-family: monospace !important;
    color: #231f20;
    margin-left: 0;
    padding-top: 5px;
    font-size: 18px;
    transition: margin-left 0.3s ease-out;
  }
`;

const AppLogo = styled("img")`
  width: 60px;
  transition: width 0.3s ease-out;

  &.low {
    width: 100px;
    transition: width 0.3s ease-out;
  }
`;

const CodeInput = styled("input")`
  height: 50px;
  font-size: 26px;
  color: #424242;
  text-align: center;
  font-family: monospace;
  outline: none;
  outline-width: 0;
  caret-color: #424242;
  background-color: #e3e3e3;
  border: none;
  border-radius: 5px;

  &:focus {
    border: none;
  }

  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;

    &&[type="number"] {
      -moz-appearance: textfield;
    }
  }

  &::-webkit-input-placeholder {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: #807f84;
  }
`;

const CodeTypeToggleContainer = styled("div")`
  display: grid;
  grid-template-columns: auto 60px auto;
  row-gap: 20px;
  column-gap: 10px;
  align-items: center;
  justify-content: center;
`;

const CodeTypeToggleLabel = styled("div")``;

const CodeTypeToggle = styled("label")`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`;

const Button = styled("button")`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  min-height: 40px;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  background-color: #fff;
  color: #000;
  transition: opacity 0.3s ease-in-out;

  &:disabled {
    opacity: 0.3;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
  }
`;

const GenerateCodeButton = styled(Button)`
  height: 17vh;
  background-color: #98d17a;
  
  &.disabled {
    opacity: 0.3;
    transition: opacity .1s ease-out;
    pointer-events: none;
  }
`;

const BackButton = styled(Button)`
  position: sticky;
  bottom: 10px;
  left: 20px;
  z-index: 99;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;

  span {
    margin-right: 6px;
    font-weight: 500;
    transform: rotate(180deg);
  }
`;

const ExtrasMenuButton = styled(Button)`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 500px;
  left: -88px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

const ExtrasIcon = styled("div")`
  font-size: 20px;
  color: #ccc
`;

const BarcodeContainer = styled("div")`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 40px);
  width: calc(100vw - 40px);
  z-index: 99;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  padding: 0 20px;
`;

const Barcode = styled("div")`
  display: flex;
  transform: scaleX(1.4);
  height: 50vh;
`;

const BarcodeValue = styled("p")`
  font-family: monospace;
  font-size: 18px;
  letter-spacing: 1px;
  background-color: #fff;
  padding: 0 4px;
  z-index: 100;
  margin: 15px 0 0;
`;

const BarcodeWithDescription = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 5px;
  box-shadow: 20px 38px 34px -26px hsla(0, 0%, 0%, 0.2);
  padding: 20px 10px;
  box-sizing: border-box;
  margin-bottom: 25px;
  background-color: #fff;
`;

const QRcodeContainer = styled("div")`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 40px);
  width: calc(100vw - 40px);
  z-index: 99;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  padding: 0 20px;
`;

const QRcodeWithDescription = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 20px 38px 34px -26px hsla(0, 0%, 0%, 0.2);
  padding: 20px 20px;
  box-sizing: border-box;
  margin-bottom: 25px;
  background-color: #fff;
`;

const Footer = styled("div")`
  width: 100%;
  position: absolute;
  bottom: 0;
  text-align: center;
  padding: 0 0 20px 20px;
  font-size: 10px;
  font-family: monospace;
  box-sizing: border-box;
  z-index: 98;
`;

const Extras = styled("div")`
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f7f7;
  transform: translateX(100vw);
  transition: transform 0.3s;
  z-index: 99;

  &.selected {
    transform: translateX(0);
    transition: transform 0.3s;
  }
`;

const ExtrasHeader = styled("div")`
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  font-family: monospace;
  font-size: 18px
`;

const CloseExtrasButton = styled(Button)`
  position: sticky;
  bottom: 10px;
  left: 20px;
  z-index: 99;
`;

const Description = styled("div")`
  text-align: center;
  font-family: "Roboto", sans-serif;
  margin-bottom: 40px;
  
  p {
    margin: 10px 0;
  }
  
  span {
    font-family: monospace;
    font-size: 16px;
  }
`;

const Thanks = styled("div")`
  text-align: center;
  font-family: "Roboto", sans-serif;
  margin-bottom: 40px;
  
  a {
    text-decoration: none;
    color: #000;
  }

  p {
    margin: 10px 0;
  }
`;

const ServiceCodes = styled("div")`
  text-align: center;
  font-family: "Roboto", sans-serif;

  p {
    margin: 10px 0;
  }

  .row {
    display: flex;
    width: 100vw;
    margin-bottom: 20px;
  }

  .col {
    flex: 1;

    &:last-child {
      //margin-left: 1em;
    }
  }

  .tabs {
    overflow: hidden;
  }

  .tab {
    width: 100%;
    overflow: hidden;
  }

  .tab-label {
    display: flex;
    justify-content: space-between;
    padding: 15px 30px;
    font-weight: bold;
    
    &::after {
      content: "\\276F";
      width: 1em;
      height: 1em;
      text-align: center;
      transform: rotate(90deg);
      transition: all .35s;
    }
  }
  
  .content {
      max-height: 0;
      padding: 0 1em;
      transition: all .35s;
  }

  .close {
      display: flex;
      justify-content: flex-end;
      padding: 1em;
      font-size: 0.75em;
      cursor: pointer;
    }
  
`;

const TabLabel = styled("div")`
  display: flex;
  justify-content: space-between;
  padding: 15px 30px;
  font-weight: bold;

  &::after {
    content: "\\276F";
    width: 1em;
    height: 1em;
    text-align: center;
    transform: rotate(90deg);
    transition: all .35s;
  }
  
  &.opened::after {
    transform: rotate(270deg);
  }
`;

const MagicCodeButton = styled(Button)`
  border: 1px solid #ccc
`;

const TabContent = styled("div")`
  display: flex;
  max-height: 0;
  padding: 0 1em;
  background: white;
  transition: all .2s;
  justify-content: space-around;

  &.opened {
    min-height: 45px;
    max-height: 45px;
    padding: 1em;
    transition: all .35s;
  }
`;

const MagicBarcodeImage = styled("img")`
  background-color: white;

`;

export default function App() {
  const [code, setCode] = useState([]);
  const [showHome, setShowHome] = useState(true);
  const [showBarcode, setShowBarcode] = useState(false);
  const [codeAsArray, setCodeAsArray] = useState([]);
  const [lowView, setLowView] = useState(true);
  const [checkCharacter, setCheckCharacter] = useState(0);
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [keyboardTypeNumber, setKeyboardTypeNumber] = useState(true);
  const [isBarcode, setIsBarcode] = useState(true);
  const [showExtras, setShowExtras] = useState(false);
  const [honeywellChecked, setHoneywellChecked] = useState(false);
  const [zebraChecked, setZebraChecked] = useState(false);
  const [showHoneywellHID, setShowHoneywellHID] = useState(false);
  const [showZebraSSI, setShowZebraSSI] = useState(false);
  const [showZebraHID, setShowZebraHID] = useState(false);

  const CodeEntered = Array.from(code);

  let globalControlSum = 104;
  let controlCharacter = "";

  for (let i = 0; i < CodeEntered.length; i++) {
    let char = CodeEntered[i];
    const result = symbolPatterns.find((x) => x.sign === char).value;
    const indexSwitch = i + 1;
    let controlSum = indexSwitch * result;
    globalControlSum = globalControlSum + controlSum;
    controlCharacter = globalControlSum % 103;
  }

  const controlSign = symbolPatterns.find((s) => s.value === controlCharacter);

  useEffect(() => {
    if (controlSign) {
      setCheckCharacter(controlSign.sign);
    }
  }, [controlSign]);

  function GenerateBarcode() {
    setCodeAsArray(CodeEntered);
    setShowBarcode(true);
  }

  function BackToInput() {
    setShowBarcode(false);
    setShowHome(true);
    const timer = setTimeout(() => {
      setCode("");
    }, 200);
    return () => {
      clearTimeout(timer);
    };
  }

  function ScrollToTop() {
    window.scrollTo(0, 0);
  }

  function HonewellToggle() {
    setHoneywellChecked(!honeywellChecked);
    setZebraChecked(false);
  }

  function ZebraToggle() {
    setZebraChecked(!zebraChecked);
    setHoneywellChecked(false);
  }

  return (
    <Main>
      <CSSTransition
        classNames="flip"
        in={showHome}
        timeout={300}
        unmountOnExit
        onEnter={() => setShowBarcode(false)}
      >
        <HomeView>
          <Header className={lowView ? "low" : ""}>
            <AppLogo className={lowView ? "low" : ""} src={lowView ? logo : logoAlt} />
            <h1>croC0DE</h1>
          </Header>
          <CodeTypeToggleContainer>
            <CodeTypeToggleLabel onClick={() => setIsBarcode(true)}>
              BARCODE
            </CodeTypeToggleLabel>
            <CodeTypeToggle className="switch">
              <input
                  type="checkbox"
                  checked={!isBarcode}
                  onClick={() => setIsBarcode(!isBarcode)}
              />
              <span className="slider round" />
            </CodeTypeToggle>
            <CodeTypeToggleLabel onClick={() => setIsBarcode(false)}>
              QR CODE
            </CodeTypeToggleLabel>
            <CodeTypeToggleLabel style={{textAlign: "right"}} onClick={() => setKeyboardTypeNumber(true)}>
              NUMBER
            </CodeTypeToggleLabel>
            <CodeTypeToggle className="switch">
              <input
                type="checkbox"
                checked={!keyboardTypeNumber}
                onClick={() => setKeyboardTypeNumber(!keyboardTypeNumber)}
              />
              <span className="slider round" />
            </CodeTypeToggle>
            <CodeTypeToggleLabel onClick={() => setKeyboardTypeNumber(false)}>
              MIXED
            </CodeTypeToggleLabel>
          </CodeTypeToggleContainer>
                <CodeInput
                    type={keyboardTypeNumber ? "number" : "text"}
                    inputType={keyboardTypeNumber ? "numberDecimal" : ""}
                    inputmode="numeric"
                    pattern={keyboardTypeNumber ? "[0-9]*" : ""}
                    placeholder={
                      showPlaceholder
                          ? "Touch here and enter the code"
                          : "Enter the code"
                    }
                    onChange={(e) => setCode(e.target.value)}
                    onFocus={() => {
                      setLowView(!lowView);
                      setShowPlaceholder(false);
                    }}
                    onBlur={() => {
                      setLowView(!lowView);
                      setShowPlaceholder(true);
                      ScrollToTop();
                    }}
                />
                <GenerateCodeButton
                    className={code.length < 1 ? "disabled" : ""}
                    onClick={GenerateBarcode}
                >
                  GENERATE
                </GenerateCodeButton>
        </HomeView>
      </CSSTransition>
      <CSSTransition
        classNames="flip-alt"
        in={showBarcode}
        timeout={300}
        unmountOnExit
        onExited={() => setShowHome(true)}
        onEnter={() => setShowHome(false)}
      >{
        isBarcode ? (
            <BarcodeContainer>
              <BarcodeWithDescription>
                <Barcode>
                  <CodeSymbol sign={"startB"} />
                  {codeAsArray.map((item, i) => (
                      <CodeSymbol key={i} sign={item} />
                  ))}
                  <CodeSymbol sign={checkCharacter} />
                  <CodeSymbol sign={"stop"} />
                </Barcode>
                <BarcodeValue>{code}</BarcodeValue>
              </BarcodeWithDescription>
              <BackButton onClick={BackToInput}>
                <span>➝</span> BACK
              </BackButton>
            </BarcodeContainer>
        ) : (
            <QRcodeContainer>
              <QRcodeWithDescription>
                <QRCode value={code} />
                <BarcodeValue>{code}</BarcodeValue>
              </QRcodeWithDescription>
              <BackButton onClick={BackToInput}>
                <span>➝</span> BACK
              </BackButton>
            </QRcodeContainer>
        )
      }
      </CSSTransition>
        <Extras className={showExtras ? "selected" : ""}>
          {showHome && (
              <ExtrasMenuButton onClick={() => setShowExtras(true)}>
                EXTRAS
                <ExtrasIcon>★★★</ExtrasIcon>
              </ExtrasMenuButton>
          )}
          <ExtrasHeader>
            <AppLogo src={logo} />
            <h1>croC0DE</h1>
          </ExtrasHeader>
          <Description>
            <p>Easy-peasy qr and barcode generator</p>
            <p>© 2021 <span>&#60;web:dawca/&#62;</span></p>
          </Description>
          <Thanks>
            <p>Th♡nks to:</p>
            <p><a href="https://selman.nyc/" target="_blank">Selman Design</a> for app logo</p>
            <p>Paulina Biedroń for app description</p>
          </Thanks>
          <ServiceCodes>
            <p>Magic Codes:</p>
            <div className="row">
              <div className="col">
                <div className="tabs">
                  <div className="tab">
                    <TabLabel className={honeywellChecked ? "opened" : ""} onClick={HonewellToggle}>Honeywell</TabLabel>
                    <TabContent className={honeywellChecked ? "opened" : ""}>
                      {showHoneywellHID ? (
                              <MagicBarcodeImage onClick={() => setShowHoneywellHID(false)} alt="" src={honeywellHID} />
                          ) : (
                              <>
                                <MagicCodeButton onClick={() => setShowHoneywellHID(true)}>HID Keyboard</MagicCodeButton><MagicCodeButton disabled>SSI over USB</MagicCodeButton>
                              </>
                        )}
                    </TabContent>
                  </div>
                  <div className="tab">
                    <TabLabel className={zebraChecked ? "opened" : ""} onClick={ZebraToggle}>Zebra</TabLabel>
                    <TabContent  className={zebraChecked ? "opened" : ""}>
                      {showZebraSSI || showZebraHID ? (
                          <MagicBarcodeImage onClick={showZebraSSI ? () => setShowZebraSSI(false) : () => setShowZebraHID(false)} alt="" src={showZebraSSI ? zebraSSI : zebraHID} />
                      ) : (
                          <>
                            <MagicCodeButton onClick={() => setShowZebraHID(true)}>HID Keyboard</MagicCodeButton><MagicCodeButton onClick={() => setShowZebraSSI(true)}>SSI over USB</MagicCodeButton>
                          </>
                      )}
                    </TabContent>
                  </div>
                </div>
              </div>
            </div>
          </ServiceCodes>
          <CloseExtrasButton onClick={() => setShowExtras(false)}>close ➝</CloseExtrasButton>
        </Extras>
          <Footer>
            Easy-peasy qr and barcode generator<br/>© 2021 &#60;web:dawca/&#62;
          </Footer>
    </Main>
  );
}
