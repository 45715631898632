import styled from "@emotion/styled";

import { symbolPatterns } from "./symbolPatterns";

const Symbol = styled("div")`
  display: flex;
  height: 100%;
`;

const Line = styled("div")`
  min-width: 1px;
  height: 100%;

  &.fill {
    background-color: #000;
  }
`;

export const CodeSymbol = (props) => {
  const char = props.sign;

  const symbolObject = symbolPatterns.find((p) => p.sign === char).pattern;
  const myFunc = (num) => Number(num);
  const intArr = Array.from(String(symbolObject), myFunc);

  return (
    <Symbol>
      {intArr.map((item, i) => (
        <Line key={i} className={item === 1 ? "fill" : ""} />
      ))}
    </Symbol>
  );
};
